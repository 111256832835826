#schedule_content {
  min-height:60px;
  height: 100%;
  #ctp-searchSchedule {
    padding: 0 0 15px 0;
    #schedule_stop_area_lines {
      background-color: #f1f1f1;
      border-radius: 10px;
      margin: 7% 5% 4% 5%;
      display: flex;
      .autocomplete-search-icon {
        margin: auto 0 auto 2%;
        font-size: 19px;
        i {
          color: $color-main-one;
        }
      }
      label{
        padding-left: 2%;
        display: inline-flex;
      }
      input{
        overflow: visible;
        outline: none;
        text-overflow: ellipsis;
        background-color: transparent;
        border: none;
        height: 34px;
        width: 92%;
        padding-left: 10px;
      }
      .autocomplete-clear {
        width: 9%;
        padding-top: 3px;
      }
      #show_all_lines{
        margin: 6px 7px 0 0;
        cursor: pointer;
      }
    }
  }
}