.affluence-poppup {
  .kisio-global-popup {
    padding: 0px;
    display: block;
    .kisio-popup-header {
      #kisio-popup-close {
        top: 22px;
        right: 12px;
      }
    }
  }
}
