.kisio-global-popup-full {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 0;

  .kisio-global-popup {
    overflow: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    min-width: 40%;
    width: 90%;
    max-width: 600px;
    min-height: 10%;
    max-height: 90%;
    text-align: center;
    box-sizing: border-box;
    z-index: 1000;
    border-radius: 5px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    .kisio-popup-header {
      position: relative;
      height: auto;
      img {
        position: relative;
        float: left;
        max-height: 24px;
        width: auto;
      }
      #direction{
        font-size: 20px;
        font-weight: bold;
        span{
          padding: 5px 8px;
          line-height: 2;
          height: 24px;
          max-width: 24px;
          border-radius: 3px;
          font-family: Arial, sans-serif;
          font-size: 13px;
          margin: 0 10px;
        }
        img{
          float: none;
        }
        #direction_title{
          font-size: 20px;
        }
      }
      #kisio-popup-close {
        position: absolute;
        right: 0;
        font-size: 12px;
        color: $color-main-one;
        top: 7px;
        cursor: pointer;
      }
      p.kisio-popup-title, p.kisio-popup-network-information {
        margin: 0;
        text-align: left;
      }
      p.kisio-popup-occupancy-information {
        width: auto;
        height: 64px;
        margin: auto;
        padding: 18px 25px 22px 18px;
        background-color: #f0f0f0;
      }
      .disruption-line-info {
        width: 90%;
        .title {
          float: left;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
    .kisio-popup-body {
      margin: 0;
      width: 100%;
      clear: both;
      table.occupancy{
        margin: auto;
        width: 90%;
        text-align: justify;
      }
      .popup-mail-error {
        width: 400px;
        margin: 0 auto;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: 0 0 15px -4px #000;
        margin-bottom: 5px;
        background-color: #FFFFFF;
        color: #ff0000;
        text-align: center;
        border-radius: 6px;
        padding: 8px 0;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #FFFFFF transparent transparent transparent;
        }
      }
      .mail-message-container {
        color: white;
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 3%;
        &.mail-message-error {
          background-color: #a80b13;
        }
        &.mail-message-success {
          background-color: #35af57;
        }
        .ikisio {
          font-size: 1.5rem;
          margin-right: 10px;
        }
      }
      .kisio-btn-social {
        display: flex;
        justify-content: center;
        padding-top: 50px;
        iframe{
          min-height: 600px;
          width: 90%;
        }
        .btn-social-facebook {
          color: #FFFFFF;
          background-color: #3966AE;
          i {
            border-right: solid 1px;
            margin-right: 10px;
            padding-right: 10px;
          }
        }
        .btn-social-twitter {
          margin-left: 10%;
          color: #FFFFFF;
          background-color: #1da1f2;
          i {
            border-right: solid 1px;
            margin-right: 10px;
            padding-right: 10px;
          }
        }
      }
      .disruption-header {
        display: flex;
        border-bottom: 3px solid;
        padding-top: 10px;
        .schedule-disruption {
          width: 15px;
          margin-right: 15px;
        }
        &.information {
          border-bottom-color: #64a575;
        }
        &.warning {
          border-bottom-color: #ff9000;
        }
        &.disrupt {
          border-bottom-color: #d3161b;
        }
        .lines{
          border-radius: 2px;
          font-weight: bold;
          line-height: 2;
          float: left;
          height:24px;
          display: block;
          min-width: 24px;
          text-align: center;
          font-size: 12px;
          margin:0 5px 5px 0;
          &:last-of-type{
            margin:0 10px 5px 0;
          }
          padding: 0 5px;
          img{
            float: left;
          }
          span {
            padding: 0 5px;
            font-weight: bold;
            line-height: 2;
            float: left;
            height: 24px;
            display: block;
            min-width: 24px;
            text-align: center;
            border-radius: 3px;
            font-family: Arial, sans-serif;
          }
        }
      }
      .kisio-popup-body {
        .disruption-body {
          position: relative;
          display: inline-grid;
          width: 100%;
        }
      }
    }

    .kisio-popup-footer {
      margin: 40px 0;
    }
  }

  .geovelo{
    max-width: 75%;
  }
}
