/*Small devices (320px and up until 600px, excluded)*/
@media (max-width: 599.98px) {
  body {
    overflow: visible;
  }
  #board_container {
    width: 100%;
    min-width: 320px;
    left: 0;
    height: auto;
    overflow: auto;

  }
  #board, #board_footer {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  #board_title_container {
    font-size: 12px;
  }
  .ipt-content {
    width: 85%;
  }
  .leaflet-control-zoom {
    display: none;
  }
  #board_footer {
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;
    height: auto;
    margin-bottom: 20px;
  }
  .board_footer_separator {
    border-top: 1px solid #e9e9e9;
    margin-bottom: 5px;
  }
  .ui-accordion-header {
    margin: 2% 5%;
  }
  #datepicker input {
    width: 82%;
  }
  label.ctp-modes-traveler-type {
    width: 28%;
  }
  .timepicker_wrap {
    width: 112px;
  }
  .timepicker-select {
    width: 86%;
  }
  #precision_content{
    .precision-back-button {
      margin: 2% 0 2% 3%;
    }
  }
  #journey-search-board-modes .ui-accordion-header {
    display: block;
    > div:first-child {
      padding-right: 2px;
      position: relative;
      float: left;
      top: 18px;
      left: 5px;
    }
    > div:last-child {
      padding-left: 1px;
      position: relative;
      float: right;
      top: 18px;
      right: 5px;
    }
    > * {
      max-width: 88px;
    }
    .ikisio-settings{
      position: relative;
      top: 12px;
    }
  }
  #journey-search-board-modes-settings{
    span{
      position: relative;
      top: 12px;
      margin-left: 4px;
    }
  }
  #ui-accordion-content-settings .speed_traveler_type{
    label {
      padding-left: 3%;
      i {
        margin-right: 5%;
      }
    }
  }
  #walking_speed_0 .speed_traveler_type{
    label {
      padding-left: 0;
    }
  }
  .ctp-row .ctp-col legend {
    padding-left: 0;
  }

  #notification{
    right: 50px;
  }

  #board_footer .footer_content > .ikisio {
    margin-left: 15%;
    margin-right: 10px;
  }

  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    #journey-search-board-modes .ui-accordion-header {
      justify-content: space-between;
    }
  }

  .locate-position{
    margin: 6px 15% 0 0;
  }
  .input-daterange .input-group-addon {
    padding: 0 5px 8px 5px;
  }
  .search_datetime_represents #timepicker input.input-picker, .search_datetime_represents #schedule-timepicker input.input-picker, #schedule-datetime-body #timepicker input.input-picker, #schedule-datetime-body #schedule-timepicker input.input-picker {
    width: 70%;
  }
  #search_from input, #search_to input {
    width: 60%;
  }
  #autocomplete-clear {
    margin-right: 20%;
  }
  #ctp-proximity .autocomplete-clear{
    margin-right: 21%;
  }
  #ctp-proximityPoiForm legend .check_all {
    margin-right: 9%;
  }
  #proximity-search-time label {
    padding-left: 1%;
  }
  #ctp-proximityPoiForm legend .arrow-ikisio {
    margin: 3px 5px 0 0;
  }
  label.ctp-modes-traveler-type .ikisio {
    margin-top: 2px;
    margin-left: 12px;
    margin-right: 6%;
  }
  #proximity-search-time label {
    font-size: 13px;
    padding: 2px 0 0 0;
  }
  #next_departures_results #prev_next #schedule-datepicker-bloc #schedule-datetime-body #schedule_from_datetime > .input-append > .input-group .schedule-timepicker {
    text-align: left;
    padding: 0 0 0 5px;
  }
  #kisio-full-details .kisio-co2-comparator .kisio-co2-car-compare .kisio-co2-value .kisio-co2-value-unit{
    min-width: 33%;
  }
  #journey-form-modify #cancel_search, #journey-form-new-search #cancel_search {
    float: left;
    margin: 10px 0 0 4%;
  }
  .search_datetime_represents p {
    padding-left: 10%;
    padding-right: 2%;
  }
  .kisio-result-toolbox{
    display: none;
  }

  .kisio-section-disruptions {
    padding: 0 19%;
  }
  .kisio-btn-social {
    iframe {
      min-height: 500px;
    }
  }
  .kisio-show-result{
    max-height: 310px;
  }
  #ctp-searchScheduleResults{
    max-height: 280px;
  }
  #geolocate_buttons{
    button{
      width: 123px !important;
    }
  }
  #button_action{
    display: inline-flex;
    align-items: center;
    #change_direction{
      display: inline-flex;
    }
    a{
      align-items: center !important;
      &:first-child{
        margin-left: 0 !important;
      }
      &:last-child{
        margin-right: 0 !important;
      }
    }
  }
  #label-schedule-datetime{
    padding-top: 12px !important;
  }
}