@import "~bootstrap/scss/bootstrap";
@import "ikisiofonticon/style";
@import "base/font_faces";
@import "base/variables";
@import "base/globals";
@import "base/error_pages";
@import "base/board";
@import "base/autocomplete";
@import "base/datetimepicker";
@import "base/global_popup";
@import "base/favorites";
@import "base/historic";
@import "base/journey/journey_precision";
@import "base/journey/journey_result";
@import "base/journey/journey_details";
@import "base/journey/journey_ridesharing";
@import "base/journey/affluence_popup";
@import "base/journey/journeys-filter";
@import "base/schedule/search_form";
@import "base/schedule/schedule";
@import "base/proximity/proximity";
@import "base/traffic/traffic";
@import "leaflet/leaflet";
@import "leaflet/MarkerCluster";
@import "leaflet/MarkerClusterDefault";
@import "simplebar/simplebar";
@import "base/schedule/timetable";


/*Small devices (320px and up until 600px, excluded)*/
@import "responsive/small-320-600";
/*Medium devices (tablets, 600px and up until 850px, excluded)*/
@import "responsive/medium-600-850";
/*Large devices (desktops, 850px and up until 1200px, excluded)*/
@import "responsive/large-850-1200";
/*Extra large devices (large desktops, 1200px and up until 1800px, excluded)*/
@import "responsive/extra-large-1200-1800";
/*XXL devices (XX large desktops, 1800px and up)*/
@import "responsive/extra-extra-large-over-1800";


