.journeys-filter {
    position: relative;
    margin: 0 20px;
    overflow: hidden;
    white-space: nowrap;
    height: 40px;
  
    &__item {
      padding: 5px 10px;
      line-height: 18px;
      background-color: #FFFFFF;
      border-radius: 10px;
      display: inline-block;
      min-width: 70px;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
      margin: 5px;
  
      &.selected {
        background-color: $color-main-one;
        color: #FFFFFF;
      }
    }
  
    &__section {
      font-size: 14px;
      color: #808080;
      font-weight: 600;
    }
  
    &__direction {
      position: absolute;
      background-color: #f0f0f0;
      width: 50px;
      cursor: pointer;
  
      &.right {
        transform: rotate(90deg);
        top: 59px;
        left: -15px;
        i {
          padding: 10px;
        }
      }
  
      &.left {
        transform: rotate(270deg);
        top: 47px;
        right: -7px;
        i {
          padding: 13px;
        }
      }
    }
  }
  
  .gl-hide {
    display: none;
  }
  