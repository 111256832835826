.ui-autocomplete{
  width: 30%;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  z-index: 9999;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0px;
  overflow-y: auto;
  label{
    background-color: #dbdbdb;
    margin-bottom: 0%;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    padding: 0.8% 0 0.5% 3%;
  }
  li{
    background-color: #f1f1f1;
  }
  li:hover, li.item-active{
    background-color: #dbdbdb;
  }
  li:nth-last-child(1) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    & > div {
      border: none;
    }
  }
  .title, .item-address{
    font-size: 14px;
    font-weight: 450;
    color: #3c3c3c;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }
}
.ui-corner-all {
  color: inherit;
  text-decoration: none !important;
}
.ui-menu-item{
  display: flex;
  list-style-type: none;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  &.first, &.other {
    border-bottom: 1px solid #dbdbdb;
  }
  .autocomplete-line-mode {
    margin-bottom: auto;
    margin-top: auto;
    display: flex;
    width: auto;
    img{
      height: 24px;
    }
    i {
      padding: 0 !important;
      width: auto !important;
      float: left;
    }
    .picto_line{
      height: 24px;
    }
    span {
      font-size: 12px;
      font-weight: bold;
      line-height: 2;
    }
    .autocomplete-line-code {
      padding: 0 5px;
      margin: 0 10px 0 4px;
      min-width: 24px;
      height: 24px;
      text-align: center;
      border-radius: 3px;
      font-family: $font-main-one;
    }
  }
  a {
    padding: 2.5% 0 1.5% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #3c3c3c;
    div:not(.title) {
      align-self: flex-start;
    }
    .item-autocomplete-line-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1%;
      .autocomplete-network-line {
        font-size: 9px;
        font-weight: 900;
        line-height: 2.22;
        letter-spacing: normal;
        text-align: left;
        color: #3c3c3c;
        width: auto;
        p {
          margin-bottom: 0;
          background-color: #dbdbdb;
          padding-left: 4px;
          padding-right: 4px;
          clear: both;
          float: left;
          border-radius: 2px;
        }
      }
    }
  }
}
#ctp-searchScheduleResults .ui-menu-item a{
  display: block;
}
li.ui-menu-item {
  width: 100%;
  border: none;
}
.icon-autocomplete-type{
  width: 10%;
  font-size: 23px;
  padding: 15px 15px 15px 0;
}
.icon-autocomplete-mode{
  margin-right: 7px;
  margin-left: 5px;
  color: #000;
}
.autocomplete-data-groups, autocomplete-data-list{
  display: flex;
}
#schedule-stop-area-lines-autocomplete-clear {
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: sub;
}
.distance {
  font-size: small;
  position: relative;
  right: 35px;
  top:15px;
}