/*Extra large devices (large desktops, 1800px and up)*/
@media (min-width: 1800px) {
  #board_container {
    max-width: 532px;
  }
  #precision_content{
    .precision-back-button {
      margin: 2% 0 2% 4%;
    }
  }
  .ctp-precision {
    .ctp-precision-from, .ctp-precision-to {
      .autocomplete-data-items {
        margin: 1% 0 2% 1%;
      }
    }
  }

  .kisio-global-popup.show-time-table {
    min-width: 15%;
    max-width: 30%;

    .kisio-popup-body {
      max-width: 100%;
    }
  }
}
