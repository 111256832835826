.timetable_paginate {
  &--section {
    margin: 0 auto;
    font-size: 13px;

    tr {
      display: block;
      float: left;
    }

    td {
      display: block;
      padding: 5px;
      border-right: solid 1px gray;

      &:first-of-type{
        font-weight: bold;
        border-bottom: solid 1px gray;
      }
    }

    &.is-hidden {
      display: none;
    }
  }

  &--top-controls {
    margin: 13px 0;

    &--text {
      margin: 0 6%;
    }

    .next, .back {
      cursor: pointer;
      color: $color-main-one;
      font-size: 14px;
      margin: 0;
    }

    .disabled {
      opacity: .3;
      pointer-events: none;
    }
  }

  &--bottom-controls {
    display: inline-flex;
    justify-content: center;
    margin: 0 10px;
    width: 14%;
    margin-top: 20px;

    &--item {
      cursor: pointer;
      border-radius: 50%;
      padding: 4px;
      border: 2px solid $color-main-one;
      margin: 0 5%;

      &.is-selected {
        background-color: $color-main-one;
      }
    }
  }
}

.timetable_download {
  color: $color-main-one;
  border: solid 1px $color-main-one;
  border-radius: 20px;
  padding: 10px;
  width: 200px;
  margin: 0 10px;
  text-align: center;

  &:hover {
    text-decoration: none;
    color: $color-main-one;
    background-color: #f5f5f5;
  }
}
