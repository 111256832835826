/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lato-v16-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'),
  url('../fonts/lato-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lato-v16-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lato-v16-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
  url('../fonts/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/lato-v16-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Italic'), local('Lato-Italic'),
  url('../fonts/lato-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lato-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lato-v16-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
  url('../fonts/lato-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/lato-v16-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/lato-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Urbanist-VariableFont_wght.ttf'); /* IE9 Compat Modes */
  src: local('Urbanist'), local('Urbanist'),
  url('../fonts/Urbanist-VariableFont_wght.ttf') format('truetype'), /* Safari, Android, iOS */
}