.kisio-primary-button {
  text-align: center;
}
a{
  &:hover{
    text-decoration: none;
  }
}
button[class*="kisio-primary-button"] {
  cursor: pointer;
  width: 172px;
  height: 46px;
  text-align: center;
  border-radius: 23px;
  background-color: $color-main-one;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  border: none;
  color: #ffffff;
}
button[class*="kisio-primary-button"]:hover {
  width: 176px;
  height: 50px;
  margin-top: -2px;
}
button[class*="kisio-primary-button"]:active {
  background-color: #2f605a;
}
#board_container {
  position: absolute;
  top: 130px;
  left: 2%;
  z-index: 999;
  width: 38%;
}

#board, #board_footer {
  width:100%;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

#board {
  height:auto;
}

#board_title_container, #title_container {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  padding-top: 20px;
}

#board_title_container .board_title, #title_container .board_title {
  width: 41%;
  float: left;
  padding: 0 0 10px 0;
  margin: 0 0 0 6%;
  border-bottom: 3px solid #e9e9e9;
}

#board_title_container .selected, #title_container .selected{
  border-bottom: 3px solid $color-main-one;
  font-weight: bold;
  color: $color-main-one;
  cursor:pointer;
}
#board_title_container .board_title:hover, #title_container .board_title:hover{
  font-weight: bold;
  cursor:pointer;
}

#board_title_container .title_alone, #title_container .title_alone{
  width: 88%;
  border-bottom: none;
  font-size: 21px;
  &:hover {
    border-bottom: none;
    cursor: default;
  }
}

#from_to {
  text-align: center;
}

#path {
  font-size: 12px;
  padding: 5px;
  overflow-y: scroll;
  height: 190px;
}

.selector {
  color: red;
}

.blinking {
  animation: fade 1s infinite alternate;
}

#board_content #journey_content{
  display: none;
}
#board_content #schedule_content{
  display: none;
}

#board_footer {
  margin-top: 10px;
  height: 50px;
  .footer_content {
    width: 33.1%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    text-align: center;
    color: $color-main-one;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 13px;
    &.footer_button_twice {
      width: 49.8%;
    }
    &:hover {
      text-decoration: none;
      background-color: #f5f5f5;
      border-radius: 10px;
    }
    &.footer_button_alone {
      width: 100%;
      display: flex;
      align-items: center;
      > i {
        width: 100%;
        margin: auto;
        font-size: 2rem;
        padding-right: 8px;
        text-align: right;
        &.ikisio-traffic {
          margin-bottom: 1rem;
        }
      }
      > span {
        width: 100%;
        margin: auto;
        padding-left: 8px;
        text-align: left;
        font-size: 13px;
      }
    }
    > .ikisio {
      font-size: 1rem;
      margin: -4px 10px 0 0;
    }
  }
  #footer_traffic, #footer_proximity, #footer_favorites {
    text-transform: uppercase;
  }
  #footer_traffic:hover:not(.footer_button_alone) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .footer_divider + #footer_favorites.footer_button_twice:hover {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .board_footer_separator + #footer_favorites.footer_button_twice:hover {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  #footer_favorites:hover:not(.footer_button_alone):not(.footer_button_twice) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #footer_proximity:hover:not(.footer_button_alone) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #footer_favorites {
    .img-favorite-container {
      width: 20px;
      height: 20px;
      background: $color-main-one;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border-radius: 50%;
      margin: -4px 10px 0 0;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
  .footer_divider {
    float: left;
    border-right: 1px solid #e9e9e9;
    width: 1px;
    height: 40px;
    margin-top:5px;
  }
}

.journey-search-autocomplete {
  position: relative;
}
#img-journey-form-reverse{
  color: $color-main-one;
  font-size: 0.9rem;
  &:hover, &:active {
    color: $color-main-one-dark;
  }
}
#journey-search-reverse{
  z-index: 100;
  position: absolute;
  right: 3%;
  top: 45%;
  #journey-form-reverse {
    width: 30px;
    height: 30px;
    padding: 10px 10px 8px 10px;
    border-radius: 50%;
    background-color: white;
    &:hover {
      text-decoration: none;
    }
  }
}
#search_from, #search_to {
  position: relative;
  label{
    padding-left: 2%;
    display: inline-flex;
  }
  input{
    overflow: visible;
    outline: none;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    height: 34px;
    width: 70%;
  }
  background-color: #f1f1f1;
  border-radius: 10px;
  margin: 3% 5% 0 5%;
  display: flex;
}
#search_to{
  margin: 1% 5% 0 5%;
}
.autocomplete-display-radius {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
#journey-search-board-modes {
  display: flex;
  text-align: center;
  box-shadow: none;
  z-index: 9999;
  position: relative;
  padding: 5px 0 5px 0;
}
.ui-accordion-header {
  cursor: pointer;
  font-size: 15px;
  font-stretch: normal;
  line-height: 1.25;
  font-weight: bolder;
  padding: 1% 1.5% 1% 1.5%;
  margin: 2% 5% 2% 5%;
  border-radius: 16.5px;
  height: auto;
  min-height: 33px;
  &:first-child {
    margin-right: 2%;
  }
  &:last-child {
    margin-left: 2%;
  }
  > div:first-child {
    margin-right: 5px;
  }
  > div:last-child {
    margin-left: 5px;
  }
  > * {
    display: inline-block;
  }
  &:hover, &.show {
    background-color: #f1f1f1;
  }
  .ikisio-times, .ikisio-settings {
    color: $color-main-one;
    font-size: 20px;
  }
}
#journey-search-board-modes .ui-accordion-header {
  display: flex;
  align-items: center;
  @supports not (-ms-ime-align: auto) {
    justify-content: space-evenly;
  }
}
.journey-search-board-modes-selected{
  background-color: #f1f1f1;
}
#journey-form-submit{
  text-align: center;
  padding-bottom: 5%;
  padding-top: 10px;
  height:80px;
  button{
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    border: none;
    color: #ffffff;
    &:hover {
      width: 176px;
      height: 50px;
      margin-top: -2px;
    }
    &:active{
      background-color: $color-main-one-dark;
    }
  }
}
#journey-form-modify, #journey-form-new-search{
  display: none;
  text-align: center;
  padding-bottom: 5%;
  padding-top: 10px;
  height:80px;
  button{
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    border: none;
    color: #ffffff;
    &:hover {
      width: 176px;
      height: 50px;
      margin-top: -2px;
    }
    &:active{
      background-color: $color-main-one-dark;
    }
  }

  #cancel_search{
    float: left;
    margin:5px 0 0 17%;
  }

  .modify-settings{
    background-color: #dddddd;
    border-radius: 23px;
    pointer-events: none;
    .ikisio-clear-field {
      color: white;
    }
  }
  .reset-settings{
    background-color: #ffffff;
    border-radius: 23px;
    pointer-events: unset;
  }
  button.reset-settings {
    background-color: $color-main-one;
  }
  button.modify-settings {
    background-color: rgb(221, 221, 221);
  }
}
#schedule-form-new-search{
  text-align: center;
  padding-top: 10px;
  button{
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    border: none;
    color: #ffffff;
    margin: 15px 0 0 0;
    &:active{
      background-color: $color-main-one-dark;
    }
  }
}
#autocomplete-label{
  width: auto;
  margin: auto 3%;
}
#autocomplete-clear{
  width: 5%;
  margin: auto;
  margin-right: 20%;
  line-height: 1rem;
  font-size: 1rem;
  margin-left: 5px;
}
#search-from-autocomplete-clear,
#search-to-autocomplete-clear,
#schedule-stop-area-lines-autocomplete-clear
{
  color: #B3B5B5;
  display: none;
}
.ui-accordion-content{
  display: none;
  position: relative;
  text-align: justify;
  padding: 0 3%;
  &.show {
    background-color: #f1f1f1;
  }
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 9999;
  }
  &:before {
    top: 0;
    box-shadow: inset 0px 5px 12px -6px rgba(101, 101, 101, 0.4);
  }
  &:after {
    bottom: 0;
    box-shadow: inset 0px 5px 12px -6px rgba(101, 101, 101, 0.4);
  }
}
.journey-search-datetime, .journey-search-modes{
  width: 100%;
  text-align: center;
  background-color: #f1f1f1;
  z-index: 1;
}

.journey-search-modes legend{
  font-size: 14px;
  font-weight: bold;
}

.journey-search-modes #modes_content, .journey-search-modes .ctp-row{
  padding: 10px;
  background: #fff;
  border-radius: 15px;
  margin: 10px 0;
}
.journey-search-modes ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.journey-search-modes label{
  margin: 0;
}
.journey-search-modes li{
  height: 38px;
  padding: 6px 0 0 0;
  vertical-align: middle;
}
.journey-search-modes #search_modes li, .journey-search-modes #drawdown_modes_0 li{
  border-bottom:solid 1px #f1f1f1;
}
.journey-search-modes #search_modes li:last-child, .journey-search-modes #drawdown_modes_0 li:last-child{
  border-bottom: none;
}
.journey-search-modes img{
  margin: 0 10px;
  height:20px;
}

.ipt-content{
  width: 90%;
  float: left;
}
.ipt-content .ikisio{
  margin: 0 10px;
}
label.input-toggle {
  line-height: 0;
  font-size: 0;
  display: inline-block;
  margin: 2px 0 0 0;
}

label.input-toggle > span {
  display: inline-block;
  position: relative;
  background-image: -webkit-linear-gradient(left, #848484 0%, #848484 50%, $color-main-one 50%, $color-main-one 100%);
  background-image: linear-gradient(to right, #848484 0%, #848484 50%, $color-main-one 50%, $color-main-one 100%);
  background-size: 64px 14px;
  background-position: 0;
  border-radius: 32px;
  width: 32px;
  height: 14px;
  cursor: pointer;
  -webkit-transition: background-position 0.2s ease-in;
  transition: background-position 0.2s ease-in;
}

label.input-toggle > input:checked + span { background-position: -100%; }

label.input-toggle > span:after {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  background: #F1F1F1;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  -webkit-transition: left 0.2s ease-in, background-color 0.2s ease-in, -webkit-transform 0.3s ease;
  transition: left 0.2s ease-in, background-color 0.2s ease-in, transform 0.3s ease, -webkit-transform 0.3s ease;
}

label.input-toggle > input:checked + span:after {
  left: calc(100% - 18px);
  background-color: $color-main-one;
}

label.input-toggle > span:active:after {
  -webkit-transform: translateY(-50%) scale3d(1.15, 0.85, 1);
  transform: translateY(-50%) scale3d(1.15, 0.85, 1);
}

label.input-toggle > input:disabled + span:active:after {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

label.input-toggle > input:disabled + span { cursor: default; }

label.input-toggle > input:disabled + span { background: #D5D5D5; }

label.input-toggle > input:disabled + span:after { background: #BDBDBD; }

label.input-toggle > input {
  display: block;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.ctp-row .ctp-col legend{
  margin-bottom: 0;
  padding-left: 10px;
}
.ctp-row .ctp-col{
  margin: 10px 0 0 0;
}
.ctp-row .ctp-col:first-of-type{
  margin: 0;
}
.speed_traveler_type {
  padding-top: 10px;
}
ul#traveler_type_0, ul#bike_speed_0{
  list-style-type:none;
  margin: 10px 0 0 0;
}
div#traveler_type_0 input[type="radio"], div#bike_speed_0 input[type="radio"] {
  opacity:0.01;
  z-index:100;
}
label.ctp-modes-traveler-type{
  width: 29%;
  height: 32px;
  border-radius: 5px;
  border: solid 1px $color-main-one;
  display: inline-block;
  padding: 0;
  overflow: hidden;
  text-align: center;
  line-height: 2.2;
  font-size: 14px;
  font-weight: normal;
  .ikisio {
    font-size: 1.3rem;
    float: left;
    margin-top: 5px;
    margin-left: 12px;
    margin-right: 10%;
  }
}
#bike_speed_0 {
  label.ctp-modes-traveler-type .ikisio {
    font-size: 1rem;
    margin-left: 0;
  }
}
label.ctp-modes-traveler-type:first-of-type{
  margin-right: 3%;
}
label.ctp-modes-traveler-type:last-of-type{
  margin-left: 3%;
  float: right;
}
#ui-accordion-content-now .search_datetime_represents{
  list-style-type:none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3% 0 3% 0;
  > .input-append {
    > .input-daterange {
      display: flex;
      flex-wrap: nowrap;
      > input, span {
        flex: 1 1 auto;
        width: inherit;
      }
      > .input-group-addon {
        min-width: auto;
        max-width: 30px;
      }
    }
  }
  li{
    padding: 0;
    width: 49%;
  }
  input[type="radio"] {
    opacity:0.011;
    position: absolute;
    z-index:100;
  }
  input[type="radio"]:checked + label {
    color: #ffffff;
    border:1px solid #CCC;
    box-shadow: 1.4px 1.4px 2px 0 rgba(0, 0, 0, 0.23);
    background-color: $color-main-one;
  }
  label {
    color: #000000;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: center;
    cursor:pointer;
    width: 100%;
    height: 100%;
    padding: 2% 0 0 0;
    i.icon_datetime_represents{
      font-size: large;
      vertical-align: text-bottom;
      margin-right: 3%;
    }
  }
  label:hover {
    color: #ffffff;
    background: $color-main-one;
  }
  p{
    margin: auto;
  }
}
#ui-accordion-content-settings .speed_traveler_type{
  list-style-type:none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3% 0 3% 0;
  > .input-append {
    > .input-daterange {
      display: flex;
      flex-wrap: nowrap;
      > input, span {
        flex: 1 1 auto;
        width: inherit;
      }
      > .input-group-addon {
        min-width: auto;
        max-width: 30px;
      }
    }
  }
  li{
    padding: 0;
    width: 49%;
  }
  input[type="radio"] {
    opacity:0.011;
    position: absolute;
    z-index:100;
  }
  input[type="radio"]:checked + label {
    color: #ffffff;
    border:1px solid #CCC;
    box-shadow: 1.4px 1.4px 2px 0 rgba(0, 0, 0, 0.23);
    background-color: $color-main-one;
  }
  label {
    color: #000000;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: left;
    cursor:pointer;
    width: 100%;
    height: 100%;
    padding-left: 8%;
    i.icon_datetime_represents{
      font-size: x-large;
      vertical-align: middle !important;
      margin-right: 3%;
    }
  }
  label:hover {
    color: #ffffff;
    background: $color-main-one;
  }
  p{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 2%;
  }
}

.popup {
  position: initial;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.popup .popuptext {
  width: 300px;
  background-color: #FFFFFF;
  color: #ff0000;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -80px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 15px -4px #000;
}

.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #FFFFFF transparent transparent transparent;
}

.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.error-message{
  border:solid 1px red;
}
#journey-search-board-modes-settings {
  position: relative;
}

#notification{
  display: none;
  width: 14px;
  height: 15px;
  border-radius: 7px;
  border: solid 3px #ffffff;
  position: absolute;
  right: 40px;
  top: -8px;
  background-color: $color-main-one;
}
.locate-position{
  position: absolute;
  right: 0;
  margin: 6px 12% 0 0;
  cursor: pointer;
  background: transparent;
  z-index: 9999;
}

// IE10+ & Edge Removing Browser Clear Button icon
input::-ms-clear {
  display: none;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .ui-accordion-header {
    text-align: center;
  }
  #journey-search-board-modes .ui-accordion-header {
    justify-content: space-between;
  }
  #autocomplete-label {
    padding-top: 5px;
  }
  #autocomplete-clear {
    padding-top: 8px;
  }
  #ui-accordion-content-settings {
    .speed_traveler_type {
      .ctp-modes-traveler-type {
        padding-left: 0;
        text-align: center;
        .ikisio {
          float: none;
          margin-right: 5px;
          display: inline-block;
        }
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  /* Edge specific styles go here */
  #ui-accordion-content-settings {
    .speed_traveler_type {
      .ctp-modes-traveler-type {
        padding-left: 0;
        text-align: center;
        .ikisio {
          float: none;
          margin-right: 5px;
          display: inline-block;
        }
      }
    }
  }
  #journey-search-board-modes .ui-accordion-header {
    display: block;
    text-align: center;
    > div:first-child, #notification + div {
      float: left;
    }
    > div:last-child {
      float: right;
      margin-top: 3px;
    }
  }
}

#geolocate-img{
  margin: 20px 0;
}
#geolocate_buttons{
  clear: both;
  width: 100%;
  margin-top: 20px;
  height: 50px;
  button:first-of-type{
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: white;
    border: solid 1px $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: $color-main-one;
    margin-right: 20px;
    &:hover {
      width: 176px;
      height: 50px;
      margin-top: -2px;
    }
  }
  button:last-of-type{
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    border: none;
    color: #ffffff;
    &:hover {
      width: 176px;
      height: 50px;
      margin-top: -2px;
    }
    &:active{
      background-color: $color-main-one-dark;
    }
  }
}
.geolocation-map-button{
  background: url('../../canaltppnpcore/images/icon-localisation.png') no-repeat 6px 2px;
  width: 30px;
  height: 30px;
  background-size: 65%;
  display: block;
}
.leaflet-control-locate a {
  font-size: 1.4em;
  color: #444;
  cursor: pointer;
}
.leaflet-control-locate.active a {
  color: #2074b6;
}
.leaflet-control-locate.active.following a {
  color: #fc8428;
}

.leaflet-control-locate-location circle {
  animation: leaflet-control-locate-throb 4s ease infinite;
}

@keyframes leaflet-control-locate-throb {
  0% {
    stroke-width: 1;
  }
  50% {
    stroke-width: 3;
    transform: scale(0.8, 0.8);
  }
  100% {
    stroke-width: 1;
  }
}

#geolocate-second-step{
  display: none;
}
