.kisio-result, .ctp-favorites-accordion {
  background-color: #f0f0f0;
  overflow-x: hidden;
  border-radius: 5px;
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
  }
  &:before {
    height: 5px;
    z-index: 9999;
    top: 0;
    box-shadow: inset 0 5px 12px -6px rgba(101, 101, 101, 0.4);
  }
  &:after {
    height: 15px;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(240,240,240,0) 0%,rgba(240,240,240,1) 100%);
  }
  .kisio-journey-link{
    margin: 2%;
    a{
      color: $color-main-one;
    }
  }
  .kisio-link-next{
    float: right;
  }
  .kisio-result-bloc, #list-journey_solution {
    background-color: #f0f0f0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: auto;
    .kisio-datetime-changed {
      background-color: #FFFFFF;
      margin: 4% 2% 2% 2%;
      padding: 2%;
      border-radius: 5px;
      display: flex;
      i {
        color: $color-main-one;
        margin: 3%;
        font-size: 20px;
      }
      span {
        font-size: 14px;
        p {
          margin: 0;
        }
      }
    }
    ul.kisio-result-content {
      margin: auto 2% auto 2%;
      padding: 2% 0 2% 0;

      .tabs {
        list-style: none;
        padding: 0;
        height: 50px;
        width: 100%;
      }
      .tabs li {
        display: none;
        width: 30%;
        margin: 2% 5% 2% 0;
        float: left;
        background: #fff;
        border-radius: 21px;
        padding: 5px;
        border: 1px solid #fff;
        &:last-of-type{
          margin: 2% 0;
        }
        .img_content{
          width: 50%;
          float: left;
          text-align: center;
          img{
            margin: 0 auto;
          }
        }
        .tab_content {
          width: 50%;
          text-align: center;
          color: $color-main-one;
          white-space: nowrap;
          font-weight: bold;
          display: block;
          float: right;
          img{
            width: 20px;
          }
        }
      }
      .tabs li:hover {
        color: #fff;
        cursor: pointer;
        border: 1px solid $color-main-one;
      }
    }
    li.kisio-result-shadow {
      box-shadow: 0px 4px 5px 0 rgba(66, 66, 66, 0.31);
      border-radius: 5px;
    }
    li.kisio-result-journey, .item {
      list-style-type: none;
      clear: both;
      .kisio-summary {
        border-top: 4.5px solid #ffffff;
        border-radius: 5px;
        background-color: #ffffff;
        list-style-type: none;
        margin-bottom: 2%;
        padding: 0 2% 0 2%;
        .kisio-head-global {
          display: block;
          padding-bottom: 2%;
          .kisio-head-summary {
            ul.kisio-info-points {
              display: flex;
              justify-content: space-between;
              padding: 0;
            }
            li.kisio-origin, li.kisio-destination {
              list-style-type: none;
              span {
                font-size: 14px;
              }
            }
          }
          .kisio-body-summary {
            display: block;
            > div {
              display: flex;
              justify-content: space-between;
            }
            > div:last-of-type {
              display: block;
              justify-content: normal;
            }
            .kisio-right-info {
              display: block;
              text-align: right;
              .kisio-duration-info {
                span {
                  color: $color-main-one;
                  white-space:nowrap;
                  font-weight: bold;
                }
              }
              .kisio-price-info {
                span {
                  font-size: 13px;
                  display: inline-block;
                  float: right;
                  color: #808080;
                }
              }
            }
            .kisio-left-info {
              display: block;
              margin-right: 16px;
              ul {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                li {
                  margin-bottom: 8px;
                  list-style-type: none;
                  display: -webkit-box;
                  span.kisio-line-code {
                    padding: 0 5px;
                    height: 24px;
                    position: relative;
                    display: inline-block;
                    line-height: 2;
                    min-width: 24px;
                    text-align: center;
                    border-radius: 3px;
                    font-family: $font-main-one;
                    font-weight: bold;
                    font-size: 12px;
                  }
                  .kisio-public-transport {
                    position: relative;
                    .ikisio-tad {
                      position: inherit;
                      border: solid 2px #ffffff;
                      background-color: $color-main-one;
                      background-clip: padding-box;
                      border-radius: 20px;
                      padding: 3px;
                      font-size: 9px;
                      color: #ffffff;
                      margin-left: -3px;
                      bottom: 2px;
                    }
                    .picto_line{
                      max-height: 24px;
                    }
                  }
                }
                li.kisio-division {
                  background-color: #e9e9e9;
                  border-radius: 7px;
                  height: 7px;
                  width: 7px;
                  margin: auto 5px auto 5px;
                }
              }
            }
          }
          .pt_realtime{
            justify-content: normal;
            font-size: 11px;
            margin: 10px 0 0 0;
            .picto_line{
              height: 15px;
            }
            .kisio-line-code{
              padding: 3px 5px;
              font-size: 11px;
              height: 16px;
              border-radius: 3px;
              position: relative;
              font-family: $font-main-one;
              font-weight: bold;
              text-align: center;
            }
            .section_pt_realtime{
              float: right;
              color: #3f8178;
            }
            .img-pt-realtime{
              width: 15px;
              height: 15px;
              margin: 0 5px 0 0;
            }
          }
          .kisio-footer-summary {
            display: flex;
            width: 100%;
            position: relative;
            .kisio-footer-co2 {
              display: flex;
              font-size: 12px;
              color: #808080;
              float: left;
              sub {
                bottom: -1.25em !important;
              }
            }
            .kisio-footer-disruptions {
              display: flex;
              justify-content: flex-end;
              font-size: 12px;
              font-weight: bold;
              line-height: 1.67;
              &.information {
                color: #64a575;
              }
              &.warning {
                color: #ff9000;
              }
              &.disrupt {
                color: #d3161b;
              }
            }
          }
        }
      }
      &.disruption-type-warning {
        border-top: 4.5px solid #ff9000 !important;
      }
      &.disruption-type-disrupt {
        border-top: 4.5px solid #d3161b !important;
      }
    }
    li.kisio-result-journey:last-child {
      margin-bottom: 0%;
    }
  }
  .kisio-error-message {
    background-color: #FFFFFF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    padding-bottom: 5%;
    i {
      position: absolute;
      margin-left: 4.5%;
      font-size: 25px;
      color: $color-main-one;
      background-color: #FFFFFF;
      padding: 1%
    }
    li {
      border: solid 2px $color-main-one;
      border-radius: 11px;
      list-style-type: none;
      margin: 3% 5% 0 5%;
      padding: 3%;
      text-align: center;
      width: 100%
    }
  }
  .disruption-type {
    position: relative;
    background-color: #ffffff;
    font-size: 12px;
    border-radius: 10px;
    padding: 2px;
    height: 19px;
    right: 0;
    margin-left: -3px;
    margin-top: -3px;
    &.ikisio-disrupt-information {
      color: #64a575;
    }
    &.ikisio-disrupt-warning {
      color: #ff9000;
    }
    &.ikisio-disrupt-disrupt {
      color: #a80b13;
    }
  }
  .kisio-full-details {
    margin: 5% 3%;
  }
}
.kisio-fdr-link{
  margin: 2%;
  height: 0;
  display: none;
  a{
    color: $color-main-one;
  }
}
.fdr-link-next{
  float: right;
}
#kisio-result-col {
  .kisio-result-summary {
    outline: none;
    position: relative;
    z-index: 2;
    .kisio-duplicate-section {
      margin: 12px 4% 0;
      border-top: 1px solid #e9e9e9;
      padding: 12px 0;
      display: flex;
      justify-content: space-between;
      .kisio-right-info {
        display: inline-block;
        text-align: right;
        font-weight: bold;
        .kisio-duration-info {
          span {
            color: $color-main-one;
            white-space:nowrap;
          }
        }
        .kisio-price-info {
          span {
            font-size: 13px;
            display: inline-block;
            float: right;
            color: #808080;
          }
        }
      }
      .kisio-left-info {
        display: inline-block;
        max-width: 80%;
        ul {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          margin-bottom: 0;
          margin-left: 7px;
          li {
            list-style-type: none;
            display: -webkit-box;
            span.kisio-line-code {
              padding: 0 5px;
              height: 24px;
              border-radius: 5px;
              position: relative;
              display: inline-block;
              line-height: 2;
              font-weight: bold;
              font-size: 12px;
            }
            .disruption-type {
              position: inherit;
              background-color: #ffffff;
              font-size: 12px;
              border-radius: 10px;
              padding: 2px;
              height: 19px;
              right: 0;
              margin-left: -3px;
              margin-top: -3px;
              &.ikisio-disrupt-information {
                color: #64a575;
              }
              &.ikisio-disrupt-warning {
                color: #ff9000;
              }
              &.ikisio-disrupt-disrupt {
                color: #a80b13;
              }
            }
            .kisio-public-transport {
              position: relative;
              .ikisio-tad {
                position: inherit;
                border: solid 2px #ffffff;
                background-color: $color-main-one;
                background-clip: padding-box;
                border-radius: 20px;
                padding: 3px;
                font-size: 9px;
                color: #ffffff;
                margin-left: -3px;
                bottom: 2px;
              }
              .picto_line{
                height: 24px;
              }
            }
          }
          li.kisio-division {
            background-color: #e9e9e9;
            border-radius: 7px;
            height: 7px;
            width: 7px;
            margin: auto 5px auto 5px;
          }
        }
      }
    }
  }
}
#result_content{
  position: relative;
  .result-back-button {
    margin: 3% 4% 0 4%;
  }
}
.kisio-result-toolbox {
  position: absolute;
  transform: translateX(100%);
  right: 0;
  top: 0;
  margin-top: 110px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: $color-main-one;
  .kisio-toolbox {
    list-style-type: none;
    padding: 2px 5px;
    margin: 0;
    li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.17);
      padding: 1px;
      text-align: center;
      height: 30px;
      width: 30px;
      a:hover {
        text-decoration: none;
      }
      i {
        color: #FFFFFF;
        width: 24px;
        height: 24px;
        border-radius: 20px;
        padding: 6px 4px;
        font-size: 13px;
        text-align: center;
      }
      i.ikisio-mail {
        font-size: 15px;
        display: block;
        padding-left: 7px;
        font-weight: bold;
      }
    }
    li:hover {
      background-color: $color-main-one-dark;
      border-radius: 20px;
      background-clip: content-box;
    }
    li:last-child {
      border-bottom: 0;
    }
  }
}

#mail_popup_form{
  width: 500px;
  margin: 0 auto;
  input{
    border-radius: 4px;
    border: solid 1px #e6e6e6;
    background-color: #f1f1f1;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  #contact_email_from, #contact_email_to, #contact_lastname, #contact_firstname {
    width: 400px;
    height: 47px;
  }
  #contact_content{
    width: 402px;
    height: 107px;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
    border: solid 1px #e6e6e6;
  }
  #contact_save{
    width: 160px;
    height: 44px;
    border-radius: 4px;
    background-color:$color-main-one;
    color: #fff;
    float: right;
    margin: 10px 49px 0 0;
    font-weight: bold;
    border: none;
  }
}
