
/*Errors page style*/
  .page-error {
    text-align: center;
    article {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      .error-text, .error-subtext {
        font-weight: 500;
      }
      .error-code {
        color: $color-main-one;
        font-size: 15.8vh;
        line-height: 15.8vh;
        font-weight: 900;
        margin-bottom: 18px;
        letter-spacing: 3vh;
      }
      .error-text {
        font-size: 2vh;
        line-height: 4vh;
        letter-spacing: 0.7vh;
      }
      .error-subtext {
        font-size: 21px;
        line-height: 21px;
        margin-top: 70px;
        letter-spacing: 4px;
      }
    }
  }

