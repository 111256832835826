/*Large devices (desktops, 850px and up until 1200px, excluded)*/
@media (min-width: 850px) and (max-width: 1199.98px) {
  #board_container {
    max-width: 532px;
  }
  .ipt-content {
    width: 85%;
  }
  .ui-accordion-header {
    margin: 2% 0 2% 0;
  }
  .ui-accordion-header > * {
    font-size: 13px;
  }
  #datepicker input {
    width: 82%;
  }
  #board_title_container .board_title {
    width: 43%;
    margin-left: 3%;
    font-size: 14px;

    &:last-child {
      margin-right: 3%;
    }
  }
  label.ctp-modes-traveler-type {
    width: 28%;
  }
  label.ctp-modes-traveler-type .ikisio {
    margin-left: 5px;
  }

  #journey-search-board-modes {
    margin: 0 5% 0 5%;
  }

  .kisio-result {
    .kisio-error-message {
      li {
        padding: 5%;
      }
    }
  }
  #ui-accordion-content-settings .speed_traveler_type {
    label {
      padding-left: 4%;
    }
  }
  #board_footer .footer_content > .ikisio {
    margin-left: 15%;
  }
  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    #journey-search-board-modes {
      #journey-search-board-modes-settings, #journey-search-board-modes-date {
        justify-content: space-between;
      }
    }
  }

  .locate-position{
    margin: 6px 15% 0 0;
  }
  .input-daterange .input-group-addon {
    padding: 1px 5px 8px 5px;
  }
  .search_datetime_represents #timepicker input.input-picker, .search_datetime_represents #schedule-timepicker input.input-picker, #schedule-datetime-body #timepicker input.input-picker, #schedule-datetime-body #schedule-timepicker input.input-picker {
    width: 70%;
  }
  #search_from input, #search_to input {
    width: 60%;
  }
  #autocomplete-clear {
    margin-right: 20%;
  }
  #ctp-proximity .autocomplete-clear{
    margin-right: 21%;
  }
  #ctp-proximityPoiForm legend .check_all {
    margin-right: 9%;
  }
  #proximity-search-time label {
    padding-left: 1%;
  }
  #ctp-proximityPoiForm legend .arrow-ikisio {
    margin: 3px 5px 0 0;
  }
  #next_departures_results #prev_next #schedule-datepicker-bloc #schedule-datetime-body #schedule_from_datetime > .input-append > .input-group .schedule-timepicker {
    text-align: left;
    padding: 0 0 0 5px;
  }
  #kisio-full-details .kisio-co2-comparator .kisio-co2-car-compare .kisio-co2-value .kisio-co2-value-unit{
    min-width: 33%;
  }
  #journey-form-modify #cancel_search, #journey-form-new-search #cancel_search {
    float: left;
    margin: 10px 0 0 4%;
  }
  .search_datetime_represents p {
    padding-left: 10%;
    padding-right: 2%;
  }
  #soft_mode_tab{
    .img_content{
      width: 40%;
    }
    .tab_content{
      width: 60%;
    }
  }

  .kisio-global-popup.show-time-table {
    min-width: 30%;
    max-width: 45%;

    .kisio-popup-body {
      max-width: 100%;
    }
  }

  .ctp-traffic .disruption__line-header {
    font-size: 11px;
  }
}