#proximity_content {
  display: none;
}
#title_container {
  #proximity_clear{
    cursor: pointer;
  }
  .ikisio-clear-field{
    position: absolute;
    margin-right: 32px;
    right: 0;
    font-size: 24px;
    color: $color-main-one;
  }
}
#ctp-searchProximity{
  padding: 10px;
  box-shadow: 0 5px 2px rgba(0, 0, 0, 0.3);
}
#proximity-search-form{
  padding:0 20px;
  position: relative;
  .autocomplete-clear {
    z-index: 500;
    top: 0;
  }
  .locate-position {
    top: 0;
    margin-right: 30px;
  }
  .autocomplete-search-icon, .locate-position{
    display: none;
  }
  .ikisio-zoom{
    position: absolute;
    color: $color-main-one;
    margin: 9px;
  }
  #proximity-search-input{
    padding-left: 30px;
    width: 100%;
  }
}
#proximity-search-time{
  list-style-type:none;
  flex-direction: row;
  justify-content: space-between;
  padding: 3% 20px 0 3%;
  text-align: center;
  #proximity-search-time-label{
    color:#3c3c3c;
    font-size: 12px;
  }
  #proximity-search-time-input .ctp-modes-traveler-type:last-of-type{
    float: none;
  }
  li{
    padding: 0;
    width: 49%;
  }
  input[type="radio"] {
    opacity:0.011;
    position: absolute;
    z-index:100;
  }
  input[type="radio"]:checked + label {
    color: $color-text;
    border:1px solid #CCC;
    box-shadow: 1.4px 1.4px 2px 0 rgba(0, 0, 0, 0.23);
    background-color: $color-main-one;
  }
  label {
    color: #000000;
    background-color: #ffffff;
    border-radius: 15px;
    text-align: left;
    cursor:pointer;
    width: 30%;
    padding-left: 6%;
    i.icon_datetime_represents{
      font-size: x-large;
      vertical-align: middle !important;
      margin-right: 3%;
    }
  }
  label:hover {
    color: $color-text;
    background: $color-main-one;
  }
  p{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 2%;
  }
}

#proximity-form-submit{
  text-align: center;
  padding-bottom: 5%;
  padding-top: 10px;
  height:80px;
  button{
    cursor: pointer;
    width: 172px;
    height: 46px;
    text-align: center;
    border-radius: 23px;
    background-color: $color-main-one;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    border: none;
    color: #ffffff;
    &:hover {
      width: 176px;
      height: 50px;
      margin-top: -2px;
    }
    &:active{
      background-color: $color-main-one-dark;
    }
  }
}
#proximity_search_uri {
  > div {
    position: relative;
    #myPopup {
      bottom: 40px;
      margin-top: 0;
    }
  }
  div{
    width: 100%;
  }
  label{
    display: none;
  }
  input{
    overflow: visible;
    outline: none;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    height: 34px;
    width: 85%;
    padding-left: 30px;
  }
  background-color: #f1f1f1;
  border-radius: 10px;
  margin: 5% 5% 0 0;
  display: flex;
  width: 100%;
}
#ctp-proximity {
  .ctp-results {
    h2 {
      float: left;
      margin: 0.8em 0;
      font-weight: 300;
    }
    .link-show-summary {
      margin-top: 0.188em;
      margin-right: 0.5em;
    }
    #ctp-proximity-poi {
      form {
        .ctp-proxi-accordion {

          .ui-accordion-header {
            min-height: 3.563em;
            padding: 1.125em 1.75em 1.125em 3.125em;
            width: 100%;
          }
          fieldset {
            input {
              margin: 0 0.5em
            }
          }
          .ui-accordion-content {
            border: 1px solid red;
          }
          .ui-accordion-content-active {
            padding: 1.25em 1.25em 0;
          }
        }
      }
    }
    .ctp-right {
      float: right;
    }
  }
  #poi_list {
    background-color: white;
    margin: 20px -10px 0 -10px;
    padding: 2px;
    height: auto;
    #display_poi {
      margin: 5px 0;
    }
    #display_stops_addresses {
      margin: 5px 0;
      ul.ctp-list-point {
        .arrow-ikisio {
          margin-right: 9px;
        }
        .ctp-libelle-picto {
          padding-left: 17px;
        }
      }
    }
    h3 {
      font-size: 1.5em;
      margin: 1em 0;
      clear: both;
    }
    h4, .ctp-see-map {
      font-size: 0.813em;
    }
    h4 {
      font-weight: bold;
      padding: 0 0 0.625em 0;
    }
    .ctp-see-map {
      display: block;
      margin-bottom: 0.938em;
    }
    ul.ctp-list-point {
      .arrow-ikisio{
        margin: 0 6px 0 10px;
        font-size: 14px;
      }
      & .section-poi-content {
        display: none;

      }
      fieldset {
        border-radius: 23px;
        width: auto;
        margin: -25px -5px 15px -5px;
        padding: 12px 12px 0 12px;
        legend {
          background-color: #f1f1f1;
          padding-top: 0;
          cursor: pointer;
          height: 40px;
          vertical-align: middle;
          &:active, &:focus{
            outline: none;
          }
          span.ctp-libelle-picto{
            padding-left: 10px;
            vertical-align: middle;
            font-size: 15px;
          }
          img {
            max-width: 24px;
            vertical-align: middle;
          }
          h3{
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      ul{
        padding: 0 15px;
      }
      li {
        background-color: #f1f1f1;
        clear: both;
        margin-bottom: 1.5em;
        list-style-type: none;
        width: 100%;
        border-radius: 10px;
        padding: 15px;
        position: relative;
        h4 {
          img {
            max-width: 24px;
          }
        }
        .bss-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &.ctp-stop-address {
          h4 .ikisio {
            margin-right: 5px;
            font-size: 1rem;
          }
          .secondary-button .ikisio {
            margin-right: 0;
            margin-left: 5px;
          }
        }
        .buttons_travel{
          position: absolute;
          top: 15px;
          right: 15px;
          button{
            border: none;
            background: #f1f1f1;
          }
        }
      }
    }
    .ctp-actions {
      li {
        float: left;
        border: 1px solid #e1e1e1;
        margin-right: 0.313em;

        a {
          display: block;
          width: 1.875em;
          height: 1.875em;
          padding: 0.313em;
        }
      }
    }
    .bss-availability, .car-park-availability{
      font-size: 0.8em;
      > p {
        margin-bottom: 0;
      }
    }
  }

  .ctp_information {
    background: none repeat scroll 0 0 #F4DFDF;
    padding: 0.95em 0;
    text-align: center;
    position: relative;
    z-index: 10;
    width: inherit;
    display: block;
  }
  #ctp-mapFull {
    .ctp-expand-details {
      position: relative;
    }
  }
  .ctp-responsive-map {
    #ctp-result-map {
      position: relative;
      margin-top: 5px;
    }
  }
  .ctp-proximity-poi-checkbox {
    .checkbox-container {
      width: 1.75em;
      display: table-cell;
    }
    .label-container {
      display: table-cell;
    }
    .label-struct {
      display: table;
      label {
        display: table-row;
      }
      .label-icon {
        display: table-cell;
        width: 3em;
        img{
          margin-bottom: 4px;
        }
      }
      .label-text {
        display: table-cell;
        width: auto;
      }
    }
    padding: 0.5em;
  }
  .ctp-links {
    margin-bottom: 0.5em;

    li {
      padding: 0.25em 0;

      a {
        font-size: 0.9em;
      }
    }
  }
}
#ctp-proximity .autocomplete-clear{
  display: none;
  position: absolute;
  margin: 6px 60px 0 0;
  right: 0;
  cursor: pointer;
  i{
    color: #B3B5B5;
  }
}
#ctp-proximityPoiForm {
  legend{
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    height: 36px;
    padding-top: 9px;
    .arrow-ikisio{
      float: left;
      margin: 3px 10px 0 0;

    }
    > span{
      float: right;
      margin: 0 10% 0 0;
    }
    .check_all{
      float: right;
      margin: -3px 7% 0 0;
      position: relative;

      &:hover input + .checkmark {
        background-color: #ccc;
        cursor: pointer;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 25px;
        width: 25px;
        z-index: 99999;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background: #fff;
        border: solid 1px #eee;
        border-radius: 15px;
      }

      input:checked + .checkmark {
        background-color: $color-main-one;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      input:checked + .checkmark:after {
        display: block;
      }
      .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .ctp-button-primary{
    display: none;
  }
}
.ctp-proximity-poi-checkbox {
  width: 100%;
  height: 46px;
  border-radius: 23px;
  margin: 0 0 15px 0;
  padding: 9px 18px;
  cursor: pointer;
  span{
    cursor: pointer;
  }
  .label-icon img{
    margin-bottom: 4px;
  }
  .checkbox-container {
    float: right;
    display: block;
    position: relative;
    margin: 0 19px 0 0;

    &:hover input + .checkmark {
      background-color: #ccc;
      cursor: pointer;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 25px;
      width: 25px;
      z-index: 99999;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background: #fff;
      border: solid 1px #eee;
      border-radius: 15px;
    }

    input:checked + .checkmark {
      background-color: $color-main-one;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  input:checked + .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .label-container{
    float: left;
  }
}

.poi-unchecked{
  background-color: #F4F4F4;
}
.poi-checked, .category_checked{
  background-color: lighten($color-main-one, 50%);
}
.category_unchecked{
  background-color: #f1f1f1;
}

#proximity-results{
  padding: 10px 10px 0 10px;
  height: auto;
  max-height: 65vh;
  display: none;
  #ctp-poi-bloc legend{
    margin: 12px 0;
  }
  .ctp-proxi-accordion fieldset > div{
    padding: 0 10px;
    border-radius: 23px;
    background-color: var(--$color-main-one);
  }
  .ctp-proxi-accordion:last-child {
    .ctp-proximity-poi-checkbox:last-child {
      margin-bottom: 0;
    }
  }
}

#mapid {
  .marker-cluster-small {
    background-color: $color-main-one-dark;
  }
  .marker-cluster-small div {
    background-color: $color-main-one;
  }

  .marker-cluster-medium {
    background-color: $color-main-one-dark;
  }
  .marker-cluster-medium div {
    background-color: $color-main-one;
  }

  .marker-cluster-large {
    background-color: $color-main-one-dark;
  }
  .marker-cluster-large div {
    background-color: $color-main-one;
  }
  .proximity-poi-icon {
    z-index: 199 !important;
  }
}

.proximity-poi-popup {
  #leaflet-popup-header {
    padding-right: 40px;
    display: flex;
    align-items: center;
    &:not(.nocontent) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .img-mode-container {
      min-width: 40%;
    }
    img {
      margin-right: 10px;
      vertical-align: top;
      float: left;
      width: 24px;
    }
    .lines {
      display: inline-block;
      float: none;
      margin-right: 5px;
      margin-bottom: 5px;
      margin-left: 0;
      flex: 1 1 auto;
      max-width: 60px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .disruption-type{
      position: inherit;
      height: 15px;
      margin-left: -9px;
      background: #fff;
      border-radius: 8px;
      margin-top: -6px;
      margin-right: 0;
    }
  }
  #leaflet-popup-header{
    .lines{
      border-radius: 2px;
      font-weight: bold;
      line-height: 2;
      float: left;
      height:24px;
      display: block;
      min-width: 24px;
      text-align: center;
      font-size: 12px;
      padding: 0 5px;
      img{
        float: left;
      }
      span {
        padding: 0 5px;
        font-weight: bold;
        line-height: 2;
        float: left;
        height: 24px;
        display: block;
        min-width: 24px;
        text-align: center;
        border-radius: 3px;
        font-family: Arial, sans-serif;
      }
    }
  }
  #leaflet-popup-content {
    padding: 10px 10px 20px 10px;
    &.nocontent {
      display: none;
    }
    .proximity-poi-info-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .proximity-poi-info-left-side {
        width: 72%;
        .proximity-poi-address {
          padding-left: 10px;
        }
      }
      .proximity-poi-info-right-side {
        text-align: right;
        .proximity-poi-distance {
          padding-right: 5px;
          img {
            transform: scaleX(-1);
            width: 18px;
            height: 18px;
          }
          &.proximity-poi-freefloating{
            font-weight: bold;
          }
        }
      }
    }
    .poi-proximity-buttons-container {
      margin-top: 10px;
    }
  }
}
#mapid.leaflet-container a.leaflet-popup-close-button {
  font-size: 34px;
  font-weight: normal;
  padding: 20px 3px 0 0;
  width: auto;
  top: 0;
  right: 0;
}
.leaflet-tooltip.walk-legend {
  border-radius: 16px;
  background-color: $color-main-one;
  border: none;
  color: white;
  font-weight: bold;
  padding: 5px 15px;
}

.leaflet-interactive.solutions-circle {
  fill: $color-main-one;
  fill-opacity: 0.15;
  stroke: lighten($color-main-one, 9.5%);
}
#div_show_poi_list{
  width: 100%;
  padding-top: 10px;
  height: 70px;
  margin: 0 0 10px 0;
  display: none;
}
#show_poi_list{
  cursor: pointer;
  width: 210px;
  height: 46px;
  text-align: center;
  border-radius: 23px;
  background-color: $color-main-one;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  border: none;
  color: $color-text;
  display: block;
  margin: 0 auto 20px auto;
  &:hover {
    width: 214px;
    height: 50px;
  }

  &:active {
    background-color: $color-main-one-dark;
  }
}
.poi_list {
  display: none;
  ul.ctp-list-point{
    padding: 0 5px;
  }
}
#proximity-show-hide-stops {
  text-align: center;
  button {
    color: #000000;
    background-color: #ffffff;
    border-radius: 15px;
    cursor:pointer;
    width: auto;
    text-align: center;
    padding: 1% 3%;
    &:hover {
      color: $color-text;
      background: $color-main-one;
    }
  }
}