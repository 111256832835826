.focus-in {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

#search_from , #search_to {
  .historic-list{
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 99999;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow-y: auto;
    padding-left: 0;
    .historic-label{
      background-color: #dbdbdb;
      margin-bottom: 0;
      padding: 0.8% 0 0.5% 3%;
      width: 100%;
      font-size: 12px;
      font-weight: bold;
    }
    .historic-item{
      background-color: #f1f1f1;
      display: flex;
      list-style-type: none;
      width: 100%;
      font-size: 14px;
      font-weight: 450;
      color: #3c3c3c;
      padding: 5% 0 5% 3%;
      border-bottom: 1px solid #dbdbdb;
      &.first, &.other {
        border-bottom: 1px solid #dbdbdb;
      }
      &:hover{
        background-color: #dbdbdb;
        cursor: pointer;
      }
    }
    li:last-child{
      border-bottom: 0px;
    }
  }
}






