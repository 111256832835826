.kisio-details, #kisio-full-details {
  display: none;
  margin: 5% 2% 5% 2%;
  .toolbox-container {
    display: none;
  }
  ol {
    padding-left: 0;
    li {
      list-style-type: none;
    }
  }
  .kisio-section-no-type {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 2%;
    margin: 2% 0 2% 0;
    .kisio-bg-section {
      display: flex;
      .kisio-bloc-left {
        margin: auto 2%;
        .kisio-picto {
          img {
            width: 26px;
            height: 26px;
          }
        }
      }
      .kisio-info {
        display: flex;
        width: 100%;
        .kisio-time {
          margin: auto 4% auto 0;
        }
        .kisio-txtInfo {
          display: grid;
          .kisio-date {
            font-weight: bold;
            color: $color-main-one;
          }
        }
        .bike-percent{
          font-weight: bold;
          color: $color-main-one;
          font-size: 13px;
        }
      }
    }
    &.kisio-section-origin, &.kisio-section-destination {
      .kisio-bloc-right {
        display: flex;
        align-items: center;
        .google_street_view_link {
          color: $color-main-one;
          font-size: 30px;
          &:hover {
            font-size: 32px;
          }
        }
      }
    }
  }

  .kisio-section-public_transport, .kisio-section-on_demand_transport, .kisio-section-transfer {
    padding: 2%;
    .kisio-bg-section {
      display: flex;
      line-height: 1.25;
      padding-left: 1%;
      .kisio-bloc-left {
        position: relative;
        min-width: 40px;
        margin-right: 2%;
        .kisio-picto {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-56%, -50%);
          em {
            padding: 30px 12px;
            border-radius: 8px;
            font-size: 23px;
            display: -webkit-box;
            text-align: center;
            min-width: 40px;
            line-height: 0.8;
            span {
              font-size: 11px;
              font-weight: bold;
              font-family: $font-main-one;
              white-space: pre;
              text-align: center;
              position: absolute;
              top: 58px;
              margin-left: -26px;
            }
          }
        }
        .kisio-picto-line {
          border-left: 4px solid;
          height: 100%;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
        }
        .kisio-picto-circle {
          width: 15px;
          height: 15px;
          border: solid 3px;
          background-color: #f0f0f0;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          border-radius: 10px;
          &.top {
            top: 0;
          }
          &.bottom {
            bottom: 0;
          }
        }
      }
      .kisio-info {
        border-radius: 5px;
        background-color: #ffffff;
        padding: 0 5% 3% 5%;
        width: 100%;
        span.kisio-time {
          font-size: 13px;
          line-height: 1.54;
          color: #808080;
        }
        .kisio-public-transport {
          padding: 6px 0;
          position: relative;
          .ikisio-tad {
            position: inherit;
            right: 0;
            border: solid 2px #ffffff;
            background-color: $color-main-one;
            background-clip: padding-box;
            border-radius: 20px;
            padding: 3px;
            font-size: 9px;
            color: #ffffff;
            margin-left: -3px;
            bottom: 2px;
          }
          .picto_line{
            height: 24px;
          }
        }
        ul {
          padding-left: 0;
          li.kisio-justify {
            justify-content: space-between;
          }
          li {
            display: flex;
            .ikisio-information-outline {
              position: relative;
              top: -15px
            }
            span.kisio-stop {
              display: inline-grid;
              .kisio-mode {
                font-size: 14px;
              }
              .kisio-direction {
                font-size: 13px;
                font-weight: bold;
              }
            }
          }
          .kisio-network {
            padding-top: 5px;
            display: list-item;
            font-size: 13px;
            line-height: 1.54;
            color: #808080;
            .kisio-network-name {
              color: $color-main-one;
              cursor: pointer;
            }
          }
        }
        .kisio-odt {
          font-size: 13px;
          line-height: 1.38;
          color: $color-main-one;
          .ikisio-tad {
            background-color: $color-main-one;
            background-clip: padding-box;
            border-radius: 20px;
            padding: 3px;
            font-size: 9px;
            color: #ffffff;
          }
        }
        .kisio-stop-lists {
          margin-top: 6px;
          .kisio-stops-description {
            .kisio-stops-group-list {
              display: none;
              &.showing {
                display: flex;
                line-height: 2.08;
                .kisio-stop-name {
                  font-size: 12px;
                  font-weight: normal;
                }
              }
            }
            .kisio-stop-time {
              font-size: 11px;
              color: #3c3c3c;
              min-width: 35px
            }
            .kisio-stop-lists-separator {
              border-left: 2px solid;
              margin: 0 8px 0 8px;
              color: #b0b0b0;
              position: relative;
              &.top {
                margin-top: 4px;
              }
              &.bottom {
                margin-bottom: 4px;
              }
              .kisio-stop-lists-extrem {
                width: 11px;
                height: 11px;
                border: solid 2px #b0b0b0;
                background-color: #ffffff;
                border-radius: 12px;
                position: absolute;
                transform: translateX(-60%);
                &.top {
                  top: 0;
                }
                &.bottom {
                  bottom: 0;
                }
              }
              .kisio-stop-lists-middel {
                width: 8px;
                height: 11px;
                border-top: solid 2px #b0b0b0;
                position: absolute;
                transform: translate(-60%, 80%);
              }
            }
            .kisio-stops-group {
              font-size: 14px;
              color: #808080;
              padding: 15px 0;
              cursor: pointer;
            }
            .kisio-stop-name {
              display: inline-grid;
              font-size: 13px;
              font-weight: bold;
            }
          }
        }
        .warning-pt {
          margin-top: 13px;
          font-size: 13px;
          .text-warning-pt {
            .ikisio {
              color: #eca33b;
            }
          }
        }
        .kisio-line-code {
          padding: 0 5px;
          height: 24px;
          position: relative;
          display: inline-block;
          padding-bottom: 3px;
          line-height: 2;
          min-width: 24px;
          text-align: center;
          border-radius: 3px;
          font-family: $font-main-one;
          font-weight: bold;
          font-size: 12px;
        }
        .kisio-disruptions {
          .kisio-traffic-info {
            margin: 15px 0px;
            .kisio-traffic-accordion-header {
              display: flex;
              float: left;
              position: relative;
              font-size: 12px;
              line-height: 0.92;
              border-bottom: 3px solid;
              margin-top: 5px;
              cursor: pointer;
              em {
                &.ikisio-disrupt-information {
                  color: #35af57;
                }
                &.ikisio-disrupt-warning {
                  color: #eca33b;
                }
                &.ikisio-disrupt-disrupt {
                  color: #a80b13;
                }
              }
              img {
                height: 14px;
                width: 14px;
              }
              .kisio-traffic-message {
                margin-left: 10px;
                p {
                  margin-bottom: 5px;
                  font-weight: bold;
                  &.kisio-warning {
                    color: #eca33b;
                  }
                }
              }
              .arrow-ikisio {
                font-weight: bold;
                position: absolute;
                right: 0;
              }
              &.kisio-information {
                border-bottom-color: #35af57;
              }
              &.kisio-warning {
                border-bottom-color: #eca33b;
              }
              &.kisio-disrupt {
                border-bottom-color: #a80b13;
              }
            }
            .kisio-traffic-content {
              display: none;
              font-size: 12px;
              li {
                img {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
      .kisio-bloc-right {
        .disruption-bulle {
          position: absolute;
          transform: translate(-90%, -20%);
          font-size: 24px;
          background: #f1f1f1;
          padding: 4px;
          border-radius: 17px;
          height: 30px;
          width: 30px;
        }
      }
    }
  }

  .kisio-section-transfer {
    position: relative;
    .kisio-bloc-left {
      display: none;
    }
    .kisio-bloc-right {
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -8px;
      cursor: pointer;
    }
    .kisio-bg-section .kisio-info {
      padding: 9px 8px 8px 12.5px;
      .transfer-mode-img {
        float: left;
        height: 100%;
        margin-right: 18.5px;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        .ctp-duration {
          font-size: 15px;
        }
      }
      .ctp-stop {
        font-size: 13px;
      }
    }
  }

  .kisio-section-street_network, .kisio-section-self_service {
    padding: 2%;
    .kisio-bg-section {
      display: flex;
      line-height: 1.25;
      padding-left: 1%;
      .kisio-bloc-left {
        position: relative;
        min-width: 40px;
        background-position: 50% 2px;
        background-size: 12px 12px;
        height: auto;
        background-repeat: repeat-y;
        background-image: radial-gradient(#C1C1C1 25%, transparent 35%);
        .kisio-picto-street-network {
          .kisio-picto {
            font-size: 23px;
            min-width: 40px;
            line-height: 0.8;
            top: 50%;
            background-color: #f1f1f1;
            transform: translateY(-50%);
            position: absolute;
            text-align: center;
            img {
              padding: 4px 0;
            }
          }
        }
      }
      .kisio-info {
        padding: 0 3% 3% 3%;
        width: 100%;
        & > span {
          font-weight: bold;
        }
        .kisio-details-duration {
          .kisio-duration {
            font-size: 15px;
          }
        }
        .kisio-detail-street-network {
          img{
            width: auto;
          }
          .kisio-detail-street-network-header {
            display: flex;
            margin: 4% 0;
            cursor: pointer;
            h4 {
              font-size: 15px;
              font-weight: bold;
              margin-bottom: 0;
            }
            em {
              margin: auto 4px;
              font-size: 11px;
            }
          }
          ol {
            display: none;
            line-height: 1.58;
            li {
              font-size: 12px;
              font-weight: normal;
              margin-bottom: 5px;
              .ikisio {
                font-size: 1.1rem;
                vertical-align: sub;
              }
            }
          }
          .geovelo-picto {
            display: block;
            padding-bottom: 5%;
          }
        }
      }
      .kisio-bloc-right {
        margin: auto 0;
        em {
          cursor: pointer;
          font-size: 22px;
        }
      }
    }
  }
  .kisio-co2-comparator {
    display: flex;
    margin-top: 6%;
    .ikisio-leaf {
      font-size: 24px;
      text-align: center;
      margin: auto 0;
      background-color: $color-main-one;
      color: #ffffff;
      border-radius: 46px;
      padding: 18px;
      z-index: 1;
    }
    .kisio-co2-car-compare {
      width: 100%;
      padding: 2%;
      border-radius: 5px;
      background-image: radial-gradient(circle at -2% 50%, #f1f1f1 37px, rgba(0, 0, 0, 0) 21px);
      margin-left: -25px;
      padding-left: 10%;
      background-color: #ffffff;
      .kisio-co2-value, .kisio-co2-car-comparison {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .kisio-co2-mode-text {
          color: $color-main-one;
          font-weight: bold;
          min-width: 35%;
          font-size: 13px;
        }
        .kisio-co2-separator {
          width: 100%;
          border-bottom: solid 2px #F1F1F1;
          margin-bottom: 2%;
        }
        .kisio-co2-value-unit {
          min-width: 30%;
          text-align: right;
          font-size: 13px;
        }
      }
      .co2-one-result {
        transform: translateY(50%);
      }
    }
  }
  .kisio-section-park, .kisio-section-bss_rent, .kisio-section-bss_put_back {
    padding: 2%;
    .kisio-bg-section {
      display: flex;
      line-height: 1.25;
      padding-left: 1%;
      .kisio-bloc-left {
        position: relative;
        min-width: 40px;
        margin-right: 2%;
        .kisio-picto {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-56%, -50%);
          em {
            padding: 17px 6px;
            border-radius: 8px;
            font-size: 28px;
            display: -webkit-box;
            text-align: center;
            min-width: 40px;
            line-height: 0.8;
            background-color:#000000;
            color:#ffffff;
            span {
              font-size: 11px;
              font-weight: bold;
              font-family: $font-main-one;
              white-space: pre;
            }
          }
        }
        .kisio-picto-car, .kisio-picto-self-service-bike {
          border-left: 4px solid;
          height: 100%;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          border-color: #000000;
        }
        .kisio-picto-circle {
          width: 15px;
          height: 15px;
          border: solid 3px;
          background-color: #f0f0f0;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          border-radius: 10px;
          border-color: #000000;
          &.top {
            top: 0;
          }
          &.bottom {
            bottom: 0;
          }
        }
      }
      .kisio-info {
        border-radius: 5px;
        background-color: #ffffff;
        padding: 3% 5%;
        width: 100%;
        font-size: 13px;
        line-height: 1.54;
        .kisio-bss_rent, .kisio-bss_put_back {
          .kisio-picto {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .mode-code-container-bss {
              display: flex;
              align-items: center;
            }
          }
        }
        .kisio-picto {
          em {
            font-size: 24px;
            margin-right: 4px;
          }
          .kisio-network-code {
            background-color: #000000;
            color: #ffffff;
            font-size: 16px;
            padding: 3px;
            font-weight: 200;
            border-radius: 6px;
            height: auto;
          }
        }
        .kisio-label {
          margin-top: 3%;
          p {
            margin: 0;
            font-size: 14px;
          }
        }
        .kisio-places-info {
          font-size: 14px;
          font-weight: bold;
          color: $color-main-one;
          display: flex;
          .kisio-available-places {
            padding: 3% 0 0 1%;
            width: 100%;
          }
        }
      }
      .kisio-bloc-right {
        margin: auto 0;
        em {
          font-size: 22px;
        }
      }
    }
  }
  .kisio-section-disruptions {
    text-align: center;
    margin-bottom: 7%;
    padding: 0 22%;
    .kisio-disruptions-lines {
      width: 100%;
      justify-content: center;
      display: inline-flex;
      .kisio-section-type {
        margin-right: 7%;
        .kisio-line-code {
          padding: 0 5px;
          height: 24px;
          position: relative;
          display: inline-block;
          min-width: 24px;
          text-align: center;
          border-radius: 3px;
          font-family: $font-main-one;
          line-height: 2;
          font-weight: bold;
          font-size: 12px;
          .disruption-type {
            position: absolute;
            height: 16px;
            width: 16px;
          }
        }
      }
    }
    .kisio-journey-traffic-link {
      padding: 10px;
      border-radius: 23px;
      background-color: $color-main-one;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 0.4px;
      border: none;
      color: #ffffff;
      &:hover {
        padding: 12px;
        text-decoration: none;
      }
      &:active{
        background-color: $color-main-one-dark;
      }
    }
  }
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .kisio-details, #kisio-full-details {
    .kisio-section-public_transport, .kisio-section-on_demand_transport {
      .kisio-bg-section {
        .kisio-bloc-left {
          .kisio-picto {
            em {
              display: block;
            }
          }
        }
      }
    }
  }
}
//For chrome only
@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
  #kisio-full-details .kisio-section-public_transport .kisio-bg-section .kisio-bloc-left .kisio-picto i span {
    float: right;
    margin: 10px 0 0 0;
    width: 100%;
    text-align: center;
  }
}
//For firefox only
@-moz-document url-prefix() {
  #kisio-full-details .kisio-section-public_transport .kisio-bg-section .kisio-bloc-left .kisio-picto i span {
    float: none;
  }
}