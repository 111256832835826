.precision-back-button {
  margin: 2% 0 2% 2%;
}

.ctp-precision {
  h2 {
    font-weight: bold;
    font-size: 15px;
    margin: 0;
  }
  p {
    font-size: 15px;
    margin: 0;
  }
  .precision-form-info{
    margin-left: 5%;
  }
  .precision-list{
    margin: 10px 14.4px;
    background: #fff;
    border-radius: 15px;
    padding: 10px;
    width: 98%;
  }
  .ctp-precision-from, .ctp-precision-to {
    background-color: #f1f1f1;
    padding-right: 4%;
    overflow-y: auto;
    position: relative;
    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 5px;
      z-index: 9999;
      box-shadow: inset 0px 5px 12px -6px rgba(101, 101, 101, 0.4);
    }
    &:after {
      bottom: 0;
    }
    .autocomplete-data-groups {
      h6 {
        font-weight: bold;
        margin-left: 2%;
        margin-top: auto;
        margin-bottom: auto;
      }
      i {
        font-size: 25px;
      }
    }
    .autocomplete-data-items {
      margin: 1% 0 2% 1.5%;
      .autocomplete-data-list {
        display: flex;
        padding-bottom: 1%;
        input[type=radio] {
          margin-top: auto;
          margin-bottom: auto;
          display: none;
        }
        input[type=radio] + label:before {
          background: #fff;
          border: 1.5px solid #ccc;
          border-radius: 50%;
          margin: -3px 6px 0 -25px;
          display: inline-block;
          vertical-align: middle;
          content: '';
          width: 15px;
          height: 15px;
        }
        input[type=radio]:checked + label:before {
          background: $color-main-one;
          border: 1px solid $color-main-one;
          box-shadow: inset 0px 0px 0px 2.5px #fff;
        }
      }
    }
    label{
      margin: auto 0 auto 25px;
    }
    ol {
      list-style-type: none;
      padding: 0px;
    }
  }
  .precision-form-submit, .precision-form-next{
    text-align: center;
    padding: 5%;
    height: 100px;
  }
  .journey-search-autocomplete {
    padding-bottom: 15px;
    .autocomplete-error {
      .autocomplete {
        border: solid 2px red !important;
        background-color: rgba(255, 0, 0, 0.18) !important;
      }
    }
    .autocomplete-locked {
      cursor: not-allowed;
      .autocomplete, .autocomplete input {
        pointer-events:none;
        color: #c1c1c1;
      }
    }
    .autocomplete-validate {
      .autocomplete {
        border: solid 2px rgba(108, 157, 130, 1) !important;
        background-color: rgba(199, 255, 192, 0.48) !important;
      }
    }
  }
  .disable-click-button button{
    background-color: #c1c1c1;
    pointer-events: none;
  }
}
