#html, body {
  margin: 0;
  padding: 0;
  font-family: $font-main-one;
  overflow: hidden;
  height: 100vh;
}
a{
  outline: none;
}
#add_road {
  margin: 15px;
}
#map {
  z-index:1;
  overflow: hidden;
}
.maps-img {
  z-index: 400;
  position: fixed;
  bottom: 30px;
  right: 10px;
  img{
    width: 45px;
  }
}
@media screen and (max-width: 600px) {
  .maps-img { display: none; }
}
#mapid {
  width:100%;
  height: 100vh;
}
#loading-box, #loading-box-specific{
  position: absolute;
  z-index: 9999;
  width: 100%;
  text-align: center;
  height: 100%;
  background: #ccc;
  opacity: 0.8;
  visibility: hidden;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    background: silver;
    width: 100%;
    height: 100%;
  }
}